<template>
	<form @submit.prevent class="formulario-container">
		<div class="formulario-linha linha-tripla">
			<div class="formulario-item medio">
				<label class="formulario-item-label">Descrição</label>
				<input type="text" v-model="form.Descricao" />
			</div>
			<div class="formulario-item medio">
				<label class="formulario-item-label">Data inicio</label>
				<datepicker v-model="form.DataInicio" @selected="formatarDataInicial" :language="ptBR" />
			</div>
			<div class="formulario-item medio">
				<label class="formulario-item-label">Data final</label>
				<datepicker v-model="form.DataFim" @selected="formatarDataFinal" :language="ptBR" />
			</div>

			<div class="formulario-item medio">
				<label class="formulario-item-label">Ativo</label>
				<multiselect v-model="form.Ativo" label="nome" track-by="nome" :options="ativo" :searchable="false"
					:multiple="false" :preselect-first="true" placeholder="" select-label="Selecionar"
					deselect-label="Remover" selected-label="Selecionado" @close="fecharSelect" />
			</div>

			<div class="formulario-item medio">
				<label class="formulario-item-label">Botão Whatsapp</label>
				<multiselect v-model="form.botaoWhatsapp" label="nome" track-by="nome" :options="ativo"
					:searchable="false" :multiple="false" :preselect-first="true" placeholder=""
					select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
					:allow-empty="false" />
			</div>

			<div class="formulario-item medio">
				<label class="formulario-item-label">Ordem</label>
				<multiselect v-model="form.PosicaoCarrocel" label="posicao" track-by="posicao" :options="ordem"
					:searchable="false" :multiple="false" :preselect-first="true" placeholder=""
					select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
					@close="fecharSelect" />
			</div>

			<div class="formulario-item medio">
				<label class="formulario-item-label">Link</label>
				<input type="text" v-model="form.Link" />
			</div>

			<div class="formulario-item medio">
				<label class="formulario-item-label">Segmentação</label>
				<multiselect v-model="segmentacaoAtual" label="descricao" track-by="descricao" :options="segmentacao"
					:searchable="false" :multiple="false" placeholder="" select-label="Selecionar" deselect-label=""
					selected-label="Selecionado" :allow-empty="false" @close="fecharSelect" @input="
						(form.grupo = []),
						(form.idsSegmentacao = []),
						(assistenciaSelecionados = [])
					" />
			</div>

			<div v-if="segmentacaoAtual && segmentacaoAtual.id === 5" class="formulario-item medio">
				<label class="formulario-item-label">Grupo</label>
				<multiselect ref="grupo" v-model="form.grupo" label="descricao" track-by="descricao" :options="grupo"
					:searchable="false" :multiple="true" :allow-empty="false" placeholder="" select-label="Selecionar"
					deselect-label="Remover" selected-label="Selecionado" @close="fecharSelect()" />
			</div>

			<div v-if="segmentacaoAtual && segmentacaoAtual.id === 4" class="formulario-item medio">
				<label class="formulario-item-label">Região</label>
				<multiselect ref="regiao" v-model="form.arrRegiao" label="descricao" track-by="descricao"
					:options="regiao" :searchable="false" :multiple="true" :allow-empty="false" placeholder=""
					select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
					@close="fecharSelect" />
			</div>

			<div v-if="segmentacaoAtual && segmentacaoAtual.id === 6" class="formulario-item medio">
				<label class="formulario-item-label">Escolha a autorizada</label>
				<multiselect ref="assistencia" v-model="franquiaAutorizada" label="descricao" track-by="descricao"
					:options="opFranqAutorizada" :searchable="true" :multiple="false" :allow-empty="false"
					placeholder="" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
					@input="(assistenciaSelecionados = []), (form.grupo = [])" />
			</div>

			<div v-if="franquiaAutorizada.id == 1 && segmentacaoAtual.id === 6" class="formulario-item medio">
				<label class="formulario-item-label">Assistência</label>
				<multiselect ref="assistencia" label="descricao" track-by="descricao" v-model="form.grupo"
					:options="assistencia" :searchable="true" :allow-empty="false" placeholder=""
					select-label="Selecionar" deselect-label=" " selected-label="Selecionado"
					@input="assistenciaSelecionado()" />
			</div>

			<div v-if="franquiaAutorizada.id == 2 && segmentacaoAtual.id === 6" class="formulario-item medio">
				<label class="formulario-item-label">Franquia</label>
				<multiselect ref="assistencia" label="descricao" track-by="descricao" :options="franquia"
					v-model="form.grupo" :searchable="true" :multiple="false" :allow-empty="false" placeholder=""
					select-label="Selecionar" deselect-label=" " selected-label="Selecionado"
					@input="assistenciaSelecionado()" />
			</div>
		</div>

		<div class="box-excluir-todos">
			<p class="btn-excluir" @click="(assistenciaSelecionados = []), (form.grupo = [])"
				v-if="assistenciaSelecionados.length > 0">
				Limpar seleção
			</p>
		</div>
		<div id="box-filtro-assistencia" v-if="assistenciaSelecionados.length > 0">
			<div v-if="assistenciaSelecionados.length > 0" class="tabela-informacoes">
				<div class="
                    box-colunas-assistencia
                    tabela-linha tabela-linha-informacoes
                    quebra-linha
                  ">
					<div v-for="(assistencia, index) in assistenciaSelecionados" :key="index"
						class="coluna-nome-assistencia">
						<p>{{ assistencia.descricao }}</p>
						<button @click="excluirassistencia(assistencia.id)" class="btn-excluir-assistencia">
							X
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="formulario-linha linha-dupla">
			<div class="formulario-item container-upload">
				<label class="formulario-item-label">Imagem Desktop</label>
				<input id="input-imagem-desktop" type="file" @change="
					atualizarImagem('ImagemDesktop', 'dataImagemDesktop', $event)
				" />
				<div class="upload-div" :style="imagemBgDesktop" :class="{ active: dataImagemDesktop }">
					<span v-if="dataImagemDesktop == null">800px x 275px</span>
				</div>
				<div>
					<label for="input-imagem-desktop" class="upload-btn">Adicionar Imagem</label>
					<transition name="fade">
						<a v-if="dataImagemDesktop" class="remove-image" @click="
							removerImagem(
								'ImagemDesktop',
								'dataImagemDesktop',
								'input-imagem-desktop'
							)
						">
							Remover
						</a>
					</transition>
				</div>
			</div>

			<div class="formulario-item container-upload">
				<label class="formulario-item-label">Imagem Mobile</label>
				<input id="input-imagem-mobile" type="file"
					@change="atualizarImagem('ImagemMobile', 'dataImagemMobile', $event)" />
				<div class="upload-div" :style="imagemBgMobile" :class="{ active: dataImagemMobile }">
					<span v-if="dataImagemMobile == null">300px x 274px</span>
				</div>
				<div>
					<label for="input-imagem-mobile" class="upload-btn">Adicionar Imagem</label>
					<transition name="fade">
						<a v-if="dataImagemMobile" class="remove-image" @click="
							removerImagem(
								'ImagemMobile',
								'dataImagemMobile',
								'input-imagem-mobile'
							)
						">
							Remover
						</a>
					</transition>
				</div>
			</div>
		</div>
		<div class="container-btns">
			<button class="btn btn-cancelar" @click.prevent="$router.push('/cadastro-banner-home/listar')">
				Voltar
			</button>
			<button class="btn btn-laranja" @click="salvar"
				:enabled="$v.form.$invalid || !dataImagemDesktop || !dataImagemMobile">
				Salvar
			</button>
		</div>
	</form>
</template>

<script>
import AwesomeMask from "awesome-mask";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import moment from "moment";
import {
	editarBannerHome,
	cadastrarBannerHome,
	tipoDeFiltro,
	obterDetalhesBanner
} from "@/services/banner-home";
import { required, minLength } from "vuelidate/lib/validators";
import {
	listarRegioes,
	listarAssistencias,
	listarGrupo
} from "@/services/autorizadas";

export default {
	name: "FormularioBannerHome",
	props: ["editar", "dados"],
	components: {
		Multiselect,
		Datepicker
	},
	directives: {
		mask: AwesomeMask
	},
	data() {
		return {
			ptBR: ptBR,
			dataImagemDesktop: null,
			dataImagemMobile: null,
			segmentacaoAtual: null,
			DataInicio: null,
			DataFim: null,
			selectTodosAssistencia: [],
			selectTodosFranquia: [],
			franquiaAutorizada: [],
			opFranqAutorizada: [
				{
					id: 1,
					descricao: "Assistencia"
				},
				{
					id: 2,
					descricao: "Franquia"
				}
			],
			form: {
				NovaImagemDesktop: null,
				NovaImagemMobile: null,
				ImagemDesktop: null,
				ImagemMobile: null,
				Link: null,
				Descricao: null,
				PosicaoCarrocel: null,
				DataInicio: null,
				DataFim: null,
				Ativo: null,
				assistencia: null,
				botaoWhatsapp: null,
				idsSegmentacao: [],
				arrRegiao: [],
				grupo: []
			},
			dadosEditar: {},
			ordem: [
				{ posicao: 1 },
				{ posicao: 2 },
				{ posicao: 3 },
				{ posicao: 4 },
				{ posicao: 5 }
			],
			segmentacao: [],

			grupo: [],

			regiao: [],

			ativo: [
				{ id: true, nome: "SIM" },
				{ id: false, nome: "NÃO" }
			],

			assistencia: [],
			assistenciaSelecionados: [],
			arrRegiao: [],
			SegmentacaoSelecionada: []
		};
	},
	validations: {
		form: {
			nome: { required, minLength: minLength(5) },
			cliente: { required },
			ean: { required },
			descricao: { required, minLength: minLength(5) }
		}
	},
	computed: {
		// listaClientesCarregado() {
		//   return this.$store.state.clientes.listaClientesCarregado;
		// },
		// listaClientes() {
		//   return this.$store.state.clientes.listaClientes;
		// },
		imagemBgDesktop() {
			return `background-image: url(${this.dataImagemDesktop}); background-position: center; background-size: contain; background-repeat: no-repeat;`;
		},

		imagemBgMobile() {
			return `background-image: url(${this.dataImagemMobile}); background-position: center; background-size: contain; background-repeat: no-repeat;`;
		}
	},
	created() {
		this.selecionarFiltro();
		this.listarGrupos();
		this.listarRegioes();
		this.popularDados();
	},
	methods: {
		popularDados() {
			if (this.editar) {
				const id = this.$route.params.id;
				obterDetalhesBanner(id).then(resp => {
					let editar = resp.data.banner;
					this.form.Descricao = editar.descricao;
					this.form.DataInicio = editar.dataInicio;
					this.form.DataFim = editar.dataFim;
					const ativo = this.ativo.find(item => item.id == editar.ativo);
					this.form.Ativo = ativo;

					if (editar.posicaoCarrocel === 0) {
						editar.posicaoCarrocel = 1;
					}

					const posicaoCarrocel = this.ordem.find(
						item => item.posicao == editar.posicaoCarrocel
					);
					this.form.PosicaoCarrocel = posicaoCarrocel;

					this.form.Link = editar.link;
					this.dataImagemDesktop = editar.imagemDesktop;
					this.dataImagemMobile = editar.imagemMobile;

					const segmentacao = this.segmentacao.filter(
						item => item.id == editar.idAbrangencia
					);
					segmentacao[0].id === 6
						? (this.segmentacaoAtual = { id: 6, descricao: "Por Autorizada" })
						: (this.segmentacaoAtual = segmentacao[0]);

					editar.idsSegmentacao.forEach(element => {
						if (this.segmentacaoAtual.id === 4) {
							let reg = this.regiao.find(item => item.id == element);
							if (!this.form.arrRegiao.includes(reg)) {
								this.form.arrRegiao.push(reg);
							}
						}

						if (this.segmentacaoAtual.id === 5) {
							let reg = this.grupo.find(item => item.id == element);
							if (!this.form.grupo.includes(reg)) {
								this.form.grupo.push(reg);
							}
						}

						if (this.segmentacaoAtual.id == 6) {
							let reg = this.assistencia.find(item => item.id == element);
							this.franquiaAutorizada = { id: 1, descricao: "Assistencia" };

							if (reg == null) {
								this.franquiaAutorizada = { id: 2, descricao: "Franquia" };
								reg = this.franquia.find(item => item.id == element);
							}

							if (!this.assistenciaSelecionados.includes(reg)) {
								this.assistenciaSelecionados.push(reg);
							}
							this.form.grupo.push(reg);
						}
					});

					if (segmentacao == 5) {
						// grupo
						const grupoSelecionado = this.grupo.filter(
							item => item.id == editar.idsSegmentacao
						);
						this.form.grupo = grupoSelecionado;
					}
				});
			}
		},
		listarRegioes() {
			listarRegioes().then(resp => {
				this.regiao = resp.data;
			});
		},
		listarGrupos() {
			listarGrupo().then(resp => {
				this.grupo = resp.data;
			});
		},
		selecionarFiltro() {
			tipoDeFiltro().then(resp => {
				this.segmentacao = resp.data.filter(
					item => item.id !== 2 && item.id !== 3 && item.id !== 6
				);
				this.segmentacao.push({ id: 6, descricao: "Por Autorizada" });
			});
			this.buscarAssistenciasSegmentacao();
		},
		excluirassistencia(id) {
			const novaassitencia = this.assistenciaSelecionados.filter(
				item => item.id !== id
			);
			this.assistenciaSelecionados = novaassitencia;
			this.form.grupo = novaassitencia;
		},
		assistenciaSelecionado() {
			if (
				this.form.grupo.id == "assistencia" ||
				this.form.grupo.id == "franquia"
			) {
				this.assistenciaSelecionados = [];
				return;
			}
			this.assistenciaSelecionados.push(this.form.grupo);
			this.form.grupo = this.assistenciaSelecionados;
		},
		buscarAssistenciasSegmentacao() {
			listarAssistencias(1).then(resp => {
				this.selectTodosAssistencia = resp.data.map(item => item.id);
				this.assistencia = resp.data;
				this.assistencia.unshift({ id: "assistencia", descricao: "Todos" });
			});
			listarAssistencias(2).then(resp => {
				this.selectTodosFranquia = resp.data.map(item => item.id);
				this.franquia = resp.data;
				this.franquia.unshift({ id: "franquia", descricao: "Todos" });
			});
			this.popularDados();
		},
		formatarDataInicial(data) {
			const dataFormatada = moment(data).format("YYYY-MM-DD");
			this.form.DataInicio = dataFormatada;
		},
		formatarDataFinal(data) {
			const dataFormatada = moment(data).format("YYYY-MM-DD");
			this.form.DataFim = dataFormatada;
		},
		fecharSelect() {
			if (!this.form.cliente || this.form.cliente.length < 1) {
				this.form.cliente = null;
			}
		},
		removerImagem(form, imagem, elem) {
			this[imagem] = null;
			this.form[form] = null;
			document.querySelector(`#${elem}`).value = null;
		},
		atualizarImagem(form, imagem, $event) {
			if (!$event.target.files[0]) {
				this[imagem] = null;
				this.form[form] = null;
				return false;
			}
			this.form[form] = $event.target.files[0];
			const reader = new FileReader();
			reader.onload = bg => {
				this[imagem] = bg.target.result;
			};
			reader.readAsDataURL($event.target.files[0]);
		},
		formatarData(data) {
			return moment(data).format("YYYY-MM-DD");
		},
		salvar() {
			const data = new FormData();

			if (this.form.Descricao) {
				data.append("Descricao", this.form.Descricao);
			} else {
				this.$store.commit("SET_MODAL", {
					ativado: true,
					mensagem: "Nenhuma Descrição",
					tipo: "erro"
				});

				return;
			}

			if (this.form.DataInicio) {
				data.append(
					"DataInicio",
					this.formatarData(this.form.DataInicio) + " 00:01:00"
				);
			} else {
				this.$store.commit("SET_MODAL", {
					ativado: true,
					mensagem: "Nenhuma Data Inicial",
					tipo: "erro"
				});

				return;
			}

			if (this.form.DataFim) {
				data.append(
					"DataFim",
					this.formatarData(this.form.DataFim) + " 23:59:00"
				);
			} else {
				this.$store.commit("SET_MODAL", {
					ativado: true,
					mensagem: "Nenhuma Data Final",
					tipo: "erro"
				});

				return;
			}

			if (this.form.Ativo) {
				data.append("Ativo", this.form.Ativo.id);
			} else {
				this.$store.commit("SET_MODAL", {
					ativado: true,
					mensagem: "Selecione se está ativo ou não",
					tipo: "erro"
				});

				return;
			}

			if (this.form.PosicaoCarrocel) {
				data.append("PosicaoCarrocel", this.form.PosicaoCarrocel.posicao);
			} else {
				this.$store.commit("SET_MODAL", {
					ativado: true,
					mensagem: "Nenhuma ordem",
					tipo: "erro"
				});

				return;
			}

			if (this.form.Link) {
				data.append("Link", this.form.Link);
			} else {
				this.$store.commit("SET_MODAL", {
					ativado: true,
					mensagem: "Nenhum Link",
					tipo: "erro"
				});

				return;
			}

			data.append("ImagemDesktop", null);
			data.append("ImagemMobile", null);

			data.append(
				"IdAbrangencia",
				this.segmentacaoAtual && this.segmentacaoAtual.id
					? this.segmentacaoAtual.id
					: 1
			);

			if (this.segmentacaoAtual && this.segmentacaoAtual.id == 1) {
				this.SegmentacaoSelecionada = [];
			}

			if (this.segmentacaoAtual && this.segmentacaoAtual.id == 4) {
				if (this.form.arrRegiao.length > 0) {
					this.form.arrRegiao.map(item => {
						data.append("IdsSegmentacao", item.id);
					});
				} else {
					this.$store.commit("SET_MODAL", {
						ativado: true,
						mensagem: "Nenhuma região selecionada",
						tipo: "erro"
					});

					this.$refs.regiao.$el.focus();

					return;
				}
			}

			if (this.segmentacaoAtual && this.segmentacaoAtual.id == 5) {
				if (this.form.grupo.id) {
					this.form.grupo.map(item => {
						data.append("IdsSegmentacao", item.id);
					});
				} else {
					this.$store.commit("SET_MODAL", {
						ativado: true,
						mensagem: "Nenhum grupo selecionado",
						tipo: "erro"
					});

					this.$refs.grupo.$el.focus();

					return;
				}
			}

			if (this.segmentacaoAtual && this.segmentacaoAtual.id == 6) {
				if (this.form.grupo.id && this.form.grupo.id == "assistencia") {
					this.selectTodosAssistencia.map(item => {
						data.append("IdsSegmentacao", item);
					});
				}
				if (this.form.grupo.id && this.form.grupo.id == "franquia") {
					this.selectTodosFranquia.map(item => {
						data.append("IdsSegmentacao", item);
					});
				}

				if (
					this.form.grupo.id != "franquia" &&
					this.form.grupo.id != "assistencia"
				) {
					if (this.assistenciaSelecionados.length > 0) {
						this.assistenciaSelecionados.map(item => {
							data.append("IdsSegmentacao", item.id);
						});
					} else {
						this.$store.commit("SET_MODAL", {
							ativado: true,
							mensagem: "Nenhuma assistência selecionada",
							tipo: "erro"
						});

						this.$refs.assistencia.$el.focus();

						return;
					}
				}
			}

			if (!this.editar) {
				if (this.form.ImagemDesktop) {
					data.append("NovaImagemDesktop", this.form.ImagemDesktop);
				} else {
					this.$store.commit("SET_MODAL", {
						ativado: true,
						mensagem: "Nenhuma Imagem Desktop",
						tipo: "erro"
					});

					return;
				}

				if (this.form.ImagemMobile) {
					data.append("NovaImagemMobile", this.form.ImagemMobile);
				} else {
					this.$store.commit("SET_MODAL", {
						ativado: true,
						mensagem: "Nenhuma Imagem Mobile",
						tipo: "erro"
					});

					return;
				}

				cadastrarBannerHome(data).then(resp => {
					if (resp.data.sucesso) {
						this.$store.commit("SET_MODAL", {
							ativado: true,
							mensagem: resp.data.mensagem,
							tipo: "sucesso"
						});
						this.$router.push("/cadastro-banner-home/listar");
					}
				});
			} else {
				data.append("NovaImagemDesktop", this.form.ImagemDesktop);
				data.append("NovaImagemMobile", this.form.ImagemMobile);

				data.append("Id", this.$route.params.id);

				editarBannerHome(data).then(resp => {
					if (resp.data.sucesso) {
						this.$store.commit("SET_MODAL", {
							ativado: true,
							mensagem: resp.data.mensagem,
							tipo: "sucesso"
						});
						this.$router.push("/cadastro-banner-home/listar");
					}
				});
			}
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
#box-filtro-assistencia {
	width: 100%;

	.tabela-informacoes {
		height: auto;
		max-height: 100px;
	}

	.tabela-linha.tabela-linha-informacoes .coluna-nome-assistencia {
		width: 250px;
		padding: 0px 10px;
		margin-bottom: 4px;
	}

	.quebra-linha {
		flex-wrap: wrap;
	}

	.box-colunas-assistencia {
		width: 100%;
		display: flex;
	}
}

.box-excluir-todos {
	width: 100%;
	margin-left: 15px;
	display: flex;
}

.btn-excluir-assistencia,
.btn-excluir {
	margin: 10px 0;
	font-weight: 800;
	width: auto;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	background: #f28809;
	text-align: center;
	cursor: pointer;
	display: block;
}

.btn-excluir-assistencia {
	padding: 5px;
	border: none;
	width: 30px;
}

.width-cnpj {
	width: 33.5% !important;
}

.flex-collumn {
	display: flex;
	flex-direction: column;
}

.margin {
	border: none !important;
	margin-bottom: 2px;
}

.excluirTodos {
	color: $erro-1 !important;
	padding: 5px;
	padding-left: 0px;
	cursor: pointer;
}
</style>
